@tailwind base;
@tailwind components;
@tailwind utilities;

.video-grid {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  }
  
  @media (min-width: 1024px) {
    .video-grid {
      grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    }
}